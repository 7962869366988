import React from "react";

export default function TermsOfService() {
  return (
    <div className="container mx-auto p-6">
      <div className="bg-white shadow-lg rounded-lg p-6">
        <h1 className="text-3xl font-bold mb-6">利用規約</h1>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-4">第1条（適用）</h2>
          <p className="mb-2">
            本規約は，ユーザーと提供者との間の本サービスの利用に関わる一切の関係に適用されるものとします。
          </p>
          <p className="mb-2">
            提供者は本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。
          </p>
          <p>
            本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-4">第2条（利用登録）</h2>
          <p>
            本サービスにおいては，ユーザが第三社アカウント情報を利用しサインインした場合、ユーザは利用規約に同意したものとみなされます。
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-4">
            第3条（認証情報の管理）
          </h2>
          <p className="mb-2">
            ユーザーは，自己の責任において，本サービスで利用する認証情報を適切に管理するものとします。
          </p>
          <p>
            認証情報が第三者によって使用されたことによって生じた損害は，提供者に故意又は重大な過失がある場合を除き，提供者は一切の責任を負わないものとします。
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-4">第4条（禁止事項）</h2>
          <ul className="list-disc pl-6 space-y-2">
            <li>法令または公序良俗に違反する行為</li>
            <li>犯罪行為に関連する行為</li>
            <li>
              提供者，本サービスの他のユーザー，または第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為
            </li>
            <li>提供者のサービスの運営を妨害するおそれのある行為</li>
            <li>他のユーザーに関する個人情報等を収集または蓄積する行為</li>
            <li>不正アクセスをし，またはこれを試みる行為</li>
            <li>他のユーザーに成りすます行為</li>
            <li>
              提供者のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為
            </li>
            <li>その他，提供者が不適切と判断する行為</li>
          </ul>
        </section>

        {/* 他の条項も同様に追加 */}
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-4">
            第5条（本サービスの提供の停止等）
          </h2>
          <p>
            提供者は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>
              本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
            </li>
            <li>
              地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合
            </li>
            <li>コンピュータまたは通信回線等が事故により停止した場合</li>
            <li>その他，提供者が本サービスの提供が困難と判断した場合</li>
          </ul>
        </section>
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-4">第6条（著作権）</h2>
          <p>
            ユーザーは，自ら著作権等の必要な知的財産権を有するか，または必要な権利者の許諾を得た文章，画像や映像等の情報に関してのみ，本サービスを利用し，投稿ないしアップロードすることができるものとします。
          </p>
        </section>
        {/* 第7条 */}
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-4">
            第7条（利用制限および登録抹消）
          </h2>
          <p className="mb-2">
            提供者は，ユーザーが以下のいずれかに該当する場合には，事前の通知なく，投稿データを削除し，ユーザーに対して本サービスの全部もしくは一部の利用を制限しまたはユーザーとしての登録を抹消することができるものとします。
          </p>
          <ul className="list-disc pl-6 space-y-2 mb-2">
            <li>本規約のいずれかの条項に違反した場合</li>
            <li>登録事項に虚偽の事実があることが判明した場合</li>
            <li>提供者からの連絡に対し，一定期間返答がない場合</li>
            <li>本サービスについて，最終の利用から一定期間利用がない場合</li>
            <li>その他，提供者が本サービスの利用を適当でないと判断した場合</li>
          </ul>
          <p className="mb-2">
            前項各号のいずれかに該当した場合，ユーザーは，当然に提供者に対する一切の債務について期限の利益を失い，その時点において負担する一切の債務を直ちに一括して弁済しなければなりません。
          </p>
          <p>
            提供者は，本条に基づき提供者が行った行為によりユーザーに生じた損害について，一切の責任を負いません。
          </p>
        </section>

        {/* 第8条 */}
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-4">
            第8条（保証の否認および免責事項）
          </h2>
          <p className="mb-2">
            提供者は，本サービスに起因してユーザーに生じたあらゆる損害について、提供者の故意又は重過失による場合を除き、一切の責任を負いません。ただし，本サービスに関する提供者とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合，この免責規定は適用されません。
          </p>
          <p className="mb-2">
            前項ただし書に定める場合であっても，提供者は，提供者の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（提供者またはユーザーが損害発生につき予見し，または予見し得た場合を含みます。）について一切の責任を負いません。また，提供者の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害の賠償は，ユーザーから当該損害が発生した月に受領した利用料の額を上限とします。
          </p>
          <p>
            提供者は，本サービスに関して，ユーザーと他のユーザーまたは第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。
          </p>
        </section>

        {/* 第9条 */}
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-4">
            第9条（サービス内容の変更等）
          </h2>
          <p>
            提供者は，ユーザーへの事前の告知をもって、本サービスの内容を変更、追加または廃止することがあり、ユーザーはこれを承諾するものとします。
          </p>
        </section>

        {/* 第10条 */}
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-4">
            第10条（利用規約の変更）
          </h2>
          <p className="mb-2">
            提供者は以下の場合には、ユーザーの個別の同意を要せず、本規約を変更することができるものとします。
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>本規約の変更がユーザーの一般の利益に適合するとき。</li>
            <li>
              本規約の変更が本サービス利用契約の目的に反せず、かつ、変更の必要性、変更後の内容の相当性その他の変更に係る事情に照らして合理的なものであるとき。
            </li>
          </ul>
          <p>
            提供者はユーザーに対し、前項による本規約の変更にあたり、事前に、本規約を変更する旨及び変更後の本規約の内容並びにその効力発生時期を通知します。
          </p>
        </section>

        {/* 第11条 */}
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-4">
            第11条（個人情報の取扱い）
          </h2>
          <p>
            提供者は，本サービスの利用によって取得する個人情報については，提供者「プライバシーポリシー」に従い適切に取り扱うものとします。
          </p>
        </section>

        {/* 第12条 */}
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-4">
            第12条（権利義務の譲渡の禁止）
          </h2>
          <p>
            ユーザーは，提供者の書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。
          </p>
        </section>

        {/* 必要に応じて他の条項を追加 */}
        <section>
          <h2 className="text-xl font-semibold mb-4">
            第13条（準拠法・裁判管轄）
          </h2>
          <p>
            本規約の解釈にあたっては，日本法を準拠法とします。本サービスに関して紛争が生じた場合には，提供者の本店所在地を管轄する裁判所を専属的合意管轄とします。
          </p>
        </section>

        <p className="text-gray-500 mt-8 text-right">以上</p>
      </div>
    </div>
  );
}
