import React from "react";

export default function PrivacyPolicy() {
  return (
    <div className="container mx-auto p-6">
      <div className="bg-white shadow-lg rounded-lg p-6">
        <h1 className="text-3xl font-bold mb-6">プライバシーポリシー</h1>
        <p className="mb-6">
          サービス提供者（以下，「提供者」といいます。）は，本ウェブサイト上で提供するサービス（以下,「本サービス」といいます。）における，ユーザーの個人情報の取扱いについて，以下のとおりプライバシーポリシー（以下，「本ポリシー」といいます。）を定めます。
        </p>

        {/* 第1条 */}
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-4">第1条（個人情報）</h2>
          <p>
            「個人情報」とは，個人情報保護法にいう「個人情報」を指すものとし，生存する個人に関する情報であって，当該情報に含まれる氏名，生年月日，住所，電話番号，連絡先その他の記述等により特定の個人を識別できる情報及び容貌，指紋，声紋にかかるデータ，及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。
          </p>
        </section>

        {/* 第2条 */}
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-4">
            第2条（個人情報の収集方法）
          </h2>
          <p>
            提供者は，ユーザーが利用登録をする際に氏名，生年月日，住所，電話番号，メールアドレスなどの個人情報をお尋ねすることがあります。また，ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や決済に関する情報を,提供者の提携先（情報提供元，広告主，広告配信先などを含みます。以下，｢提携先｣といいます。）などから収集することがあります。
          </p>
        </section>

        {/* 第3条 */}
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-4">
            第3条（個人情報を収集・利用する目的）
          </h2>
          <p>提供者が個人情報を収集・利用する目的は，以下のとおりです。</p>
          <ul className="list-disc pl-6 space-y-2">
            <li>提供者サービスの提供・運営のため</li>
            <li>
              ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）
            </li>
            <li>メンテナンス，重要なお知らせなど必要に応じたご連絡のため</li>
            <li>
              利用規約に違反したユーザーや，不正・不当な目的でサービスを利用しようとするユーザーの特定をし，ご利用をお断りするため
            </li>
            <li>上記の利用目的に付随する目的</li>
          </ul>
        </section>

        {/* 第4条 */}
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-4">
            第4条（利用目的の変更）
          </h2>
          <p>
            提供者は，利用目的が変更前と関連性を有すると合理的に認められる場合に限り，個人情報の利用目的を変更するものとします。
          </p>
          <p>
            利用目的の変更を行った場合には，変更後の目的について，提供者所定の方法により，ユーザーに通知し，または本ウェブサイト上に公表するものとします。
          </p>
        </section>

        {/* 第5条 */}
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-4">
            第5条（個人情報の第三者提供）
          </h2>
          <p>
            提供者は，次に掲げる場合を除いて，あらかじめユーザーの同意を得ることなく，第三者に個人情報を提供することはありません。
          </p>
          <ul className="list-disc pl-6 space-y-2 mb-2">
            <li>人の生命，身体または財産の保護のために必要がある場合</li>
            <li>公衆衛生の向上または児童の健全な育成の推進のため</li>
            <li>
              国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行するため
            </li>
          </ul>
          <p>
            前項の定めにかかわらず，次に掲げる場合には，当該情報の提供先は第三者に該当しないものとします。
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>
              提供者が利用目的の達成に必要な範囲内において個人情報の取扱いを委託する場合
            </li>
            <li>合併その他の事由による事業の承継に伴う提供</li>
          </ul>
        </section>

        {/* 第6条 */}
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-4">
            第6条（個人情報の開示）
          </h2>
          <p>
            提供者は，本人から個人情報の開示を求められたときは，本人に対し，遅滞なくこれを開示します。ただし，開示することにより次のいずれかに該当する場合は，その全部または一部を開示しないこともあり，開示しない決定をした場合には，その旨を遅滞なく通知します。なお，個人情報の開示に際しては，1件あたり1，000円の手数料を申し受けます。
          </p>
          <ul className="list-disc pl-6 space-y-2">
            <li>
              本人または第三者の生命，身体，財産その他の権利利益を害するおそれがある場合
            </li>
            <li>
              提供者の業務の適正な実施に著しい支障を及ぼすおそれがある場合
            </li>
            <li>その他法令に違反することとなる場合</li>
          </ul>
          <p className="mt-4">
            前項の定めにかかわらず，履歴情報および特性情報などの個人情報以外の情報については，原則として開示いたしません。
          </p>
        </section>

        {/* 第7条 */}
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-4">
            第7条（個人情報の訂正および削除）
          </h2>
          <p>
            ユーザーは，提供者の保有する自己の個人情報が誤った情報である場合には，提供者が定める手続きにより，提供者に対して個人情報の訂正，追加または削除（以下，「訂正等」といいます。）を請求することができます。
          </p>
          <p className="mt-4">
            提供者は，ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の訂正等を行うものとします。
          </p>
          <p className="mt-4">
            提供者は，前項の規定に基づき訂正等を行った場合，または訂正等を行わない旨の決定をしたときは遅滞なく，これをユーザーに通知します。
          </p>
        </section>

        {/* 第8条 */}
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-4">
            第8条（個人情報の利用停止等）
          </h2>
          <p>
            提供者は，本人から，個人情報が，利用目的の範囲を超えて取り扱われているという理由，または不正の手段により取得されたものであるという理由により，その利用の停止または消去（以下，「利用停止等」といいます。）を求められた場合には，遅滞なく必要な調査を行います。
          </p>
          <p className="mt-4">
            前項の調査結果に基づき，その請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の利用停止等を行います。
          </p>
          <p className="mt-4">
            提供者は，前項の規定に基づき利用停止等を行った場合，または利用停止等を行わない旨の決定をしたときは，遅滞なく，これをユーザーに通知します。
          </p>
          <p className="mt-4">
            前2項にかかわらず，利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって，ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は，この代替策を講じるものとします。
          </p>
        </section>

        {/* 第9条 */}
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-4">
            第9条（プライバシーポリシーの変更）
          </h2>
          <p>
            本ポリシーの内容は，法令その他本ポリシーに別段の定めのある事項を除いて，ユーザーに通知することなく，変更することができるものとします。
          </p>
          <p className="mt-4">
            提供者が別途定める場合を除いて，変更後のプライバシーポリシーは，本ウェブサイトに掲載したときから効力を生じるものとします。
          </p>
        </section>

        <p className="text-gray-500 mt-8 text-right">以上</p>
      </div>
    </div>
  );
}
